/* eslint-disable ndte/sort-imports */

import 'core-js/stable';
import 'core-js/actual/promise/with-resolvers';
import 'core-js/actual/object/group-by';
import 'react-toastify/dist/ReactToastify.css';
import 'styles/application.css';

import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';

import { Root } from 'views';

import { initSentry } from 'utils/sentry';

initSentry();

const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(
  <StrictMode>
    <Root />
  </StrictMode>,
);
