import get from 'lodash/get';
import set from 'lodash/set';

import { isIsoDate } from 'utils/date';

import { walkObject } from './walk-object';

type Parser = (value: any) => any;
type ParserMap = Record<string, Parser>;

let shouldRunDevChecks = true;

export function deserialize<T = any>(data: T, parserMap: ParserMap): T {
  const paths = Object.entries(parserMap);
  const deserialized = paths.reduce(
    (deserialized, [path, parser]) => deserializeAtPath(deserialized, path, parser),
    data,
  );

  if (shouldRunDevChecks && import.meta.env.DEV) {
    runDevChecks(deserialized);
  }

  return deserialized;
}

export function withoutDevChecks<T>(callback: () => T): T {
  shouldRunDevChecks = false;
  const returnValue = callback();
  shouldRunDevChecks = true;

  return returnValue;
}

function runDevChecks<T = any>(deserialized: T) {
  walkObject(deserialized, (path, _, value) => {
    if (typeof value === 'string' && isIsoDate(value)) {
      // eslint-disable-next-line no-console
      console.error('Encountered a date string in deserialized object.', {
        deserialized,
        path,
      });
    }
  });
}

function deserializeAtPath(data: any, path: string, parser: Parser) {
  const arrayIndicatorIndex = path.indexOf('[]');

  if (arrayIndicatorIndex > -1) {
    const basePath = path.slice(0, arrayIndicatorIndex);
    const restPath = path.slice(arrayIndicatorIndex + 2);
    const array: any[] | undefined = get(data, basePath);

    if (array !== undefined && array !== null) {
      if (restPath === '') {
        return set(
          data,
          basePath,
          array.map((item) => parser(item)),
        );
      }

      return set(
        data,
        basePath,
        array.map((item) => deserializeAtPath(item, restPath.slice(1), parser)),
      );
    }

    return data;
  }

  const value = get(data, path);
  if (value !== undefined) {
    return set(data, path, parser(value));
  }

  return data;
}
