/* eslint-disable ndte/intl-imports */

import { IntlShape, MessageDescriptor } from 'react-intl';

export function isMessageDescriptor(arg: unknown): arg is MessageDescriptor {
  return typeof arg === 'object' && arg !== null && 'id' in arg && 'defaultMessage' in arg;
}

export function formatValue(messages: Record<string, MessageDescriptor>) {
  return (formatMessage: IntlShape['formatMessage'], key?: string | null): string | null => {
    if (!key) return null;
    if (!formatMessage || !messages[key]) return null;

    return formatMessage(messages[key]);
  };
}
