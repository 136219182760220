import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { ReactNode, Suspense } from 'react';
import { Provider as StoreProvider } from 'react-redux';

import { BrowserRouter } from 'router';

import { store } from 'store';

import { namedLazy } from 'utils/named-lazy';

import { AuthProvider, useAuth } from 'providers/auth-provider';
import { CapabilitiesProvider } from 'providers/capabilities-provider';
import { CurrentUserProvider } from 'providers/current-user-provider';
import { DefaultQueryClientProvider } from 'providers/default-query-client-provider';
import { ErrorBoundary } from 'providers/error-boundary';
import { LocaleProvider } from 'providers/locale-provider';
import { isRackMiniProfilerEnabled, RackMiniProfiler } from 'providers/rack-mini-profiler';
import { RouteWatcher } from 'providers/route-watcher';
import { ToastProvider } from 'providers/toast-provider';

import { Loading } from 'components/loading';

const AuthenticatedApp = namedLazy(() => import('./components/authenticated'), 'AuthenticatedApp');
const UnauthenticatedApp = namedLazy(() => import('./components/unauthenticated'), 'UnauthenticatedApp');

export function Root() {
  return (
    <StoreProvider store={store}>
      <DefaultQueryClientProvider>
        <AuthProvider>
          <AuthSwitch />
        </AuthProvider>
        <ToastProvider />

        <ReactQueryDevtools
          initialIsOpen={false}
          position="bottom-right"
          toggleButtonProps={
            isRackMiniProfilerEnabled() ? { style: { marginRight: 'calc(40px + 2.5rem)' } } : undefined
          }
        />
        <RackMiniProfiler />
      </DefaultQueryClientProvider>
    </StoreProvider>
  );
}

function AuthSwitch() {
  const { isAuthenticated } = useAuth();

  return isAuthenticated ? (
    <CurrentUserProvider>
      <CapabilitiesProvider mode="authenticated">
        <App>
          <AuthenticatedApp />
        </App>
      </CapabilitiesProvider>
    </CurrentUserProvider>
  ) : (
    <CapabilitiesProvider mode="unauthenticated">
      <App>
        <UnauthenticatedApp />
      </App>
    </CapabilitiesProvider>
  );
}

function App({ children }: { children: ReactNode }) {
  return (
    <LocaleProvider>
      <ErrorBoundary>
        <BrowserRouter future={{ v7_startTransition: import.meta.env.DEV }}>
          <Suspense fallback={<Loading />}>{children}</Suspense>
          <RouteWatcher />
        </BrowserRouter>
      </ErrorBoundary>
    </LocaleProvider>
  );
}
