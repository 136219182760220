// Actions
//======================================================================================================================

export const LOCATION_CHANGE = 'LOCATION_CHANGE';

// Action Creators
//======================================================================================================================

export function locationChange(pathname: string) {
  return {
    type: LOCATION_CHANGE,
    payload: {
      pathname,
    },
  };
}
