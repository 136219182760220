import { normalize } from 'normalizr';
import { Action } from 'redux';

import { camelize, camelizeKeys } from 'utils/camelize';
import { getType } from 'utils/get-type';

// Actions
//======================================================================================================================

export const FORBIDDEN = 'FORBIDDEN';
export const METHOD_NOT_ALLOWED = 'METHOD_NOT_ALLOWED';
export const UNPROCESSABLE_CONTENT = 'UNPROCESSABLE_CONTENT';
export const INTERNAL_SERVER_ERROR = 'INTERNAL_SERVER_ERROR';
export const NOT_FOUND = 'NOT_FOUND';
export const CONTENT_TOO_LARGE = 'CONTENT_TOO_LARGE';

export const FORM_HTTP_ERRORS = [FORBIDDEN, UNPROCESSABLE_CONTENT];

// Action Creators
//======================================================================================================================

export function forbidden({
  failureType,
  data,
}: {
  failureType: string;
  data?: { errors?: ErrorT[]; error?: ErrorT };
}): NetworkErrorResponse {
  return {
    type: FORBIDDEN,
    payload: { failureType, errors: retrieveErrors(data) },
    error: true,
  };
}

export function methodNotAllowed({ failureType }: { failureType: string }): NetworkErrorResponse {
  return {
    type: METHOD_NOT_ALLOWED,
    payload: { failureType },
    error: true,
  };
}

export function unprocessableContent({
  failureType,
  data,
}: {
  failureType: string;
  data?: { errors?: ErrorT[]; error?: ErrorT };
}): NetworkErrorResponse {
  return {
    type: UNPROCESSABLE_CONTENT,
    payload: { failureType, errors: retrieveErrors(data) },
    error: true,
  };
}

export function internalServerError({ failureType }: { failureType: string }): NetworkErrorResponse {
  return {
    type: INTERNAL_SERVER_ERROR,
    payload: { failureType },
    error: true,
  };
}

export function notFound({ failureType }: { failureType: string }): NetworkErrorResponse {
  return {
    type: NOT_FOUND,
    payload: { failureType },
    error: true,
  };
}

export function contentTooLarge({ failureType }: { failureType: string }): NetworkErrorResponse {
  return {
    type: CONTENT_TOO_LARGE,
    payload: { failureType },
    error: true,
  };
}

export function networkError({
  failureType,
  requestDescription,
  data,
}: {
  failureType: string;
  requestDescription: NetworkRequestDescription | undefined;
  data: any;
}): NetworkErrorResponse {
  if (Array.isArray(data?.errors)) {
    const errors = camelizeKeys(data.errors).map((error: any) => ({
      source: error.source ? camelize(error.source) : undefined,
      title: error.title ? error.title : undefined,
      detail: error.detail ? error.detail : undefined,
      meta: {
        request: requestDescription?.params || requestDescription?.payload,
      },
    }));

    return {
      type: failureType,
      payload: errors,
      error: true,
    };
  }

  if (data?.error) {
    return {
      type: failureType,
      payload: data.error,
      error: true,
    };
  }

  return {
    type: failureType,
    payload: data?.errors,
    error: true,
  };
}

export function networkResponse({
  successType,
  requestDescription,
  data,
}: {
  successType: string;
  requestDescription: NetworkRequestDescription;
  data: any;
}): NetworkSuccessResponse {
  const response = requestDescription.normalizeSchema ? normalize(data, requestDescription.normalizeSchema) : data;
  const actionPayload = requestDescription.actionPayload || {};

  return {
    type: successType,
    payload: {
      ...actionPayload,
      request: requestDescription.params || requestDescription.payload,
      response,
    },
    error: false,
  };
}

// Reducer
//======================================================================================================================

type NetworkState = Record<string, boolean>;

type NetworkAction = Action<string>;

export const initialState = {};

export function networkReducer(state: NetworkState = initialState, action: NetworkAction): NetworkState {
  const { type } = action;

  switch (true) {
    case type.includes('REQUEST'): {
      return { ...state, [getType(type)]: true };
    }

    case type.includes('SUCCESS'):
    case type.includes('FAILURE'): {
      return { ...state, [getType(type)]: false };
    }

    default: {
      return state;
    }
  }
}

// Utils
//======================================================================================================================

function retrieveErrors(data: { errors?: ErrorT[] | ErrorT; error?: ErrorT } | undefined): ErrorT[] {
  if (data && 'errors' in data && data.errors) {
    if (Array.isArray(data.errors)) {
      return data.errors;
    } else {
      return [data.errors];
    }
  } else if (data && 'error' in data && data.error) {
    return [data.error];
  } else {
    return [];
  }
}
