export function containsUuid(id: string) {
  const uuidRegex = new RegExp('[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}');

  return uuidRegex.test(id);
}

export function containsNumber(value: string) {
  const numberRegex = new RegExp('\\d{1,}');

  return numberRegex.test(value);
}
