/* eslint-disable ndte/router-imports */

import { forwardRef, ReactElement, ReactNode, Ref, RefAttributes } from 'react';
import * as RR from 'react-router-dom';

import { PATHS } from 'router/paths';

export {
  BrowserRouter,
  Outlet,
  useLocation,
  useParams,
  useOutletContext,
  useSearchParams,
  useRoutes,
} from 'react-router-dom';
export type { RouteObject } from 'react-router-dom';

export const SEARCH = Symbol('path_search');
export const HASH = Symbol('path_hash');
export const INFER_PARAM = Symbol('path_infer_param');

export type FinalPath = string & Brand<'final_path'>;

export type Path = (typeof PATHS)[number] | AppendAsterisk<(typeof PATHS)[number]>;

export type Params<P extends Path | FinalPath> = P extends Path
  ? [ExtractPathParams<P>] extends [never]
    ? { [k in symbol]: string }
    : ExtractPathParams<P> & { [k in symbol]: string }
  : never;

type AppendAsterisk<T> = T extends `*` ? T : T extends `${string}/` ? `${T}*` : T extends string ? `${T}/*` : never;

type ExtractPathParams<T, Fallback = never> = T extends `${string}:${infer Param}/${infer Rest}`
  ? { [k in Param | keyof ExtractPathParams<Rest, object>]: string | symbol }
  : T extends `${string}:${infer Param}`
  ? { [k in Param]: string | symbol }
  : Fallback;

export type PathParamsProps<P extends Path | FinalPath> = [ExtractPathParams<P>] extends [never]
  ? { params?: Params<P> }
  : { params: Params<P> };

export function useNavigate() {
  const baseNavigate = RR.useNavigate();
  const actualParams = RR.useParams();

  function navigate<P extends Path | FinalPath>(
    ...[to, params, options]: [ExtractPathParams<P>] extends [never]
      ? [to: P, params?: Params<P> | null, options?: RR.NavigateOptions]
      : [to: P, params: Params<P>, options?: RR.NavigateOptions]
  ): void;
  function navigate(delta: number): void;
  function navigate(...args: any[]) {
    if (args.length === 1) {
      const delta = args[0];

      return baseNavigate(delta);
    } else {
      const path = args[0];
      const params = args[1];
      const to = makePath(path, resolveInfer(params, actualParams));
      const options = args[2];

      return baseNavigate(to, options);
    }
  }

  return navigate;
}

export const useMatch = RR.useMatch as <P extends Path | FinalPath>(
  pattern: P,
) => RR.PathMatch<RR.ParamParseKey<P>> | null;

export const Route = RR.Route as <P extends Path>(
  props: Omit<RR.PathRouteProps, 'path'> & { path: P },
) => ReactElement | null;

export function Routes({ root, children, routes }: { root: Path; children?: ReactNode; routes?: RR.RouteObject[] }) {
  const makeRelative = (routes: RR.RouteObject[]) => {
    return routes.map((route) => {
      if (route.path && route.path.startsWith(root)) route.path = route.path.substring(root.length);
      if (route.children) route.children = makeRelative(route.children);

      return route;
    });
  };

  const absoluteRoutes = routes || RR.createRoutesFromChildren(children);
  const relativeRoutes = makeRelative(absoluteRoutes);
  const element = RR.useRoutes(relativeRoutes);

  return element;
}

export function Link<P extends Path | FinalPath>({
  to,
  params,
  ...props
}: Omit<RR.LinkProps, 'to'> & { to: P } & PathParamsProps<P>) {
  return <RR.Link to={makePath(to, resolveInfer(params, RR.useParams()))} {...props} />;
}

type TypedNavLink = <P extends Path | FinalPath>(
  props: Omit<RR.NavLinkProps, 'to'> & { to: P } & PathParamsProps<P> & RefAttributes<HTMLAnchorElement>,
) => ReactElement;

export const NavLink = forwardRef(function NavLink<P extends Path | FinalPath>(
  { to, params, ...props }: Omit<RR.NavLinkProps, 'to'> & { to: P } & PathParamsProps<P>,
  forwardedRef: Ref<HTMLAnchorElement>,
) {
  const location = RR.useLocation();
  const isMatch = RR.useMatch(to);

  if (isMatch && location.search !== '') {
    params = { [SEARCH]: location.search, ...params } as any as Params<P>;
  }

  return <RR.NavLink to={makePath(to, resolveInfer(params, RR.useParams()))} {...props} ref={forwardedRef} />;
}) as TypedNavLink;

export function Navigate<P extends Path | FinalPath>({
  to,
  params,
  ...props
}: Omit<RR.NavigateProps, 'to'> & { to: P } & PathParamsProps<P>) {
  return <RR.Navigate to={makePath(to, resolveInfer(params, RR.useParams()))} {...props} />;
}

export function resolveInfer<P extends Path | FinalPath>(
  params: Params<P> | undefined,
  actualParams: Readonly<RR.Params<string>>,
) {
  const normalized = { ...params } as Params<P>;

  for (const [key, value] of Object.entries(normalized)) {
    if (value === INFER_PARAM) {
      if (import.meta.env.DEV && !(key in actualParams)) throw new Error(`Could not infer param "${key}"`);
      normalized[key] = actualParams[key];
    }
  }

  return normalized;
}

export function makePath<P extends Path | FinalPath>(
  path: P,
  ...[params]: [ExtractPathParams<P>] extends [never] ? [params?: Params<P>] : [params: Params<P>]
) {
  if (!params) return path as FinalPath;

  const search = params[SEARCH];
  const hash = params[HASH];

  let result = path.replace(/:([^:/]+)/g, (_, $1) => params[$1]);
  if (search) result += search.startsWith('?') ? search : `?${search}`;
  if (hash) result += hash.startsWith('#') ? hash : `#${hash}`;

  return result as FinalPath;
}
