/* eslint-disable ndte/humps-imports */

import {
  camelize as _camelize,
  camelizeKeys as _camelizeKeys,
  decamelize as _decamelize,
  decamelizeKeys as _decamelizeKeys,
  HumpsOptions,
  HumpsProcessorParameter,
} from 'humps';

import { containsNumber, containsUuid } from './string';

interface Options {
  separator?: string | undefined;
}

export const camelize = camelizeOverride;

export function camelizeKeys(str: any) {
  return _camelizeKeys(str, { process: camelizeOverride });
}

export function decamelize(value: string, options?: Options) {
  return decamelizeOverride(value, _decamelize, options);
}

export function decamelizeKeys(str: any, options?: Options) {
  return _decamelizeKeys(str, { ...options, process: decamelizeOverride });
}

// Overwrites

function camelizeOverride(value: string) {
  if (containsUuid(value) || containsNumber(value)) {
    return value;
  }

  return _camelize(value);
}

function decamelizeOverride(value: string, convert: HumpsProcessorParameter, options?: HumpsOptions) {
  if (containsUuid(value) || containsNumber(value)) {
    return value;
  }

  return convert(value, options);
}
