import { useState } from 'react';
import { AnyAction, Dispatch } from 'redux';

import {
  contentTooLarge,
  forbidden,
  internalServerError,
  methodNotAllowed,
  networkError,
  notFound,
  unprocessableContent,
} from 'store/modules/network';

import { useAppDispatch } from 'hooks/use-redux';

interface ErrorArgs {
  failureType: string;
  error: Record<string, any>;
  requestDescription?: NetworkRequestDescription;
}

export function storeErrorHandler(dispatch: Dispatch<AnyAction>) {
  return ({ error, requestDescription, failureType }: ErrorArgs) => {
    if (import.meta.env.DEV && import.meta.env.MODE !== 'test') {
      // eslint-disable-next-line no-console
      console.error(error);
    }

    const { response } = error;

    // 403 Forbidden
    // The HTTP 403 Forbidden response status code indicates that the server understands the request but refuses to
    // authorize it.
    // https://developer.mozilla.org/en-US/docs/Web/HTTP/Status/403
    if (response?.status === 403) {
      dispatch({ type: failureType, payload: null, error: true });

      return dispatch(forbidden({ failureType, data: response?.data }));
    }

    // 404 Not Found
    // The HTTP 404 Not Found response status code indicates that the server cannot find the requested resource. Links
    // that lead to a 404 page are often called broken or dead links and can be subject to link rot
    // https://developer.mozilla.org/en-US/docs/Web/HTTP/Status/404
    if (response?.status === 404) {
      dispatch({ type: failureType, payload: null, error: true });

      return dispatch(notFound({ failureType }));
    }

    // 405 Method Not Allowed
    // The HTTP 405 Method Not Allowed response status code indicates that the server knows the request method, but the
    // target resource doesn't support this method.
    // https://developer.mozilla.org/en-US/docs/Web/HTTP/Status/405
    if (response?.status === 405) {
      dispatch({ type: failureType, payload: null, error: true });

      return dispatch(methodNotAllowed({ failureType }));
    }

    // 413 Content Too Large
    // The HTTP 413 Content Too Large response status code indicates that the request entity is larger than limits
    // defined by server; the server might close the connection or return a Retry-After header field.
    // https://developer.mozilla.org/en-US/docs/Web/HTTP/Status/413
    if (response?.status === 413) {
      dispatch({ type: failureType, payload: null, error: true });

      return dispatch(contentTooLarge({ failureType }));
    }

    // 422 Unprocessable Content
    // The HTTP 422 Unprocessable Content response status code indicates that the server understands the content type of
    // the request entity, and the syntax of the request entity is correct, but it was unable to process the contained
    // instructions.
    // https://developer.mozilla.org/en-US/docs/Web/HTTP/Status/422
    if (response?.status === 422) {
      dispatch({ type: failureType, payload: null, error: true });

      return dispatch(unprocessableContent({ failureType, data: response?.data }));
    }

    // 500 Internal Server Error
    // The HTTP 500 Internal Server Error server error response code indicates that the server encountered an unexpected
    // condition that prevented it from fulfilling the request.
    // https://developer.mozilla.org/en-US/docs/Web/HTTP/Status/500
    if (response?.status >= 500) {
      dispatch({ type: failureType, payload: null, error: true });

      return dispatch(internalServerError({ failureType }));
    }

    return dispatch(networkError({ failureType, requestDescription, data: response?.data }));
  };
}

export function useStoreErrorHandler() {
  const dispatch = useAppDispatch();
  const [handler] = useState(() => storeErrorHandler(dispatch));

  return handler;
}
