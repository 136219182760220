import axios, { AxiosError, AxiosResponse, isAxiosError } from 'axios';
import { stringify } from 'qs';

import { forgetToken, persistToken, retrieveToken } from 'auth/token-storage';
import { UserToken } from 'auth/types';

import { walkObject } from 'api/deserialize/walk-object';
import { BASE_PATH } from 'api/url';

import { camelizeKeys, decamelizeKeys } from 'utils/camelize';
import { containsNumber, containsUuid } from 'utils/string';

const refreshURL = 'auth/refresh';
const http = axios.create({ baseURL: BASE_PATH });

type DeferredStatus = 'error' | 'success';

let refreshDefer: PromiseWithResolvers<DeferredStatus> | undefined;

function httpInterceptor(refreshSuccess: (token: UserToken) => void, refreshFailure: () => void) {
  http.interceptors.request.use(
    (config) => {
      const { _isRetry, _isAuthRefresh, noDecamelizePayload } = config;

      return {
        ...config,

        transformRequest(data, headers) {
          const { access, refresh } = retrieveToken();

          if (access && !_isAuthRefresh) {
            headers['Authorization'] = `Bearer ${access}`;
          }

          if (refresh && _isAuthRefresh) {
            headers['X-Refresh-Token'] = `Bearer ${refresh}`;
          }

          headers['Content-Type'] = data instanceof FormData ? 'multipart/form-data' : 'application/json';

          if (!(data instanceof FormData) && !_isRetry) {
            data = data ? JSON.stringify(noDecamelizePayload ? data : decamelizeKeys(data)) : undefined;
          }

          return data;
        },

        paramsSerializer: {
          serialize: (params) =>
            stringify(decamelizeKeys(params), {
              indices: false,
              arrayFormat: 'brackets',
            }),
        },
      };
    },

    (error) => {
      return Promise.reject(error);
    },
  );

  http.interceptors.response.use(
    (response: AxiosResponse<any>) => {
      const { noCamelizeResponse } = response.config;

      if (noCamelizeResponse) return response;

      if (import.meta.env.DEV) validatePayload(response.data);

      return {
        ...response,
        data: camelizeKeys(response.data),
      };
    },

    async (error: unknown) => {
      if (!isAxiosError(error)) {
        return Promise.reject(error);
      }

      const { _isRetry, _isAuthRefresh } = error.config || {};

      if (_isAuthRefresh) {
        forgetToken();
        refreshFailure();

        refreshDefer?.resolve('error');
        refreshDefer = undefined;

        return Promise.reject(error);
      }

      if (error.response?.status === 401 && !_isRetry) {
        const retryRequest = {
          ...error.config,
          headers: {},
          _isRetry: true,
          _isAuthRefresh: undefined,
        };

        if (!refreshDefer) {
          refreshDefer = Promise.withResolvers();

          return http
            .post<UserToken>(refreshURL, undefined, { _isAuthRefresh: true })
            .then((response) => {
              const token = camelizeKeys(response.data) as UserToken;

              persistToken(token, true);
              refreshSuccess(token);

              refreshDefer?.resolve('success');
              refreshDefer = undefined;

              return http(retryRequest);
            })
            .catch(() => Promise.reject(error));
        } else {
          return refreshDefer.promise.then((status) =>
            status === 'error' ? Promise.reject(error) : http(retryRequest),
          );
        }
      }

      return Promise.reject(error);
    },
  );
}

function testErrorStatusCode(error: any, statusCode: number): error is AxiosError {
  return isAxiosError(error) ? error.response?.status === statusCode : false;
}

function validatePayload(data: any) {
  walkObject(data, (path, key) => {
    if (typeof key === 'string' && containsUuid(key)) {
      // eslint-disable-next-line no-console
      console.warn(
        `Encountered a UUID key in the API response. UUIDs should not be sent as JSON keys. Keys with UUIDs are ignored when keys are camelized they remain decamelized.
    Bad: { "829dc98d-cc96-482c-bffe-068d18c57681": {},"612871d5-df2b-47a1-be24-12d48c4dcc40": {}}
    Good: [{ "id": "829dc98d-cc96-482c-bffe-068d18c57681", "data": {} }, { "id": "612871d5-df2b-47a1-be24-12d48c4dcc40", "data": {} }]\n`,
        { data, path },
      );
    }

    if (typeof key === 'string' && containsNumber(key)) {
      // eslint-disable-next-line no-console
      console.warn(
        `Encountered a key with numbers in the API response. Numbers are best avoided in JSON keys. Keys with numbers are ignored when keys are camelized they remain decamelized.
    Bad: [{ "id": "foo_bar_1", "data": {} }, { "id": "foo_bar_2", "data": {} }]
    Good: [{ "id": "foo_bar_one", "data": {} }, { "id": "foo_bar_two", "data": {} }]\n`,
        { data, path },
      );
    }
  });
}

export { http, httpInterceptor, testErrorStatusCode };
