import { useCallback, useEffect } from 'react';

import { collapseSidebar, disableToggleSidebar, enableToggleSidebar, expandSidebar } from 'store/modules/app';

import { LocalStorage, StorageKey } from 'utils/storage';

import { useAppDispatch, useAppSelector } from './use-redux';

export const WIDE_SIDEBAR_STORAGE_KEY = 'wide_sidebar' as StorageKey<boolean>;
const NARROW_SIDEBAR_WIDTH_REM = 7;
const WIDE_SIDEBAR_WIDTH_REM = 30;

export function useSidebarSettings() {
  const dispatch = useAppDispatch();
  const isWide = useAppSelector((state) => state.app.sidebar.isWide);
  const canToggle = useAppSelector((state) => state.app.sidebar.canToggle);

  const expand = useCallback(() => dispatch(expandSidebar()), [dispatch]);
  const collapse = useCallback(() => dispatch(collapseSidebar()), [dispatch]);
  const enable = useCallback(() => dispatch(enableToggleSidebar()), [dispatch]);
  const disable = useCallback(() => dispatch(disableToggleSidebar()), [dispatch]);

  useEffect(() => {
    LocalStorage.set(WIDE_SIDEBAR_STORAGE_KEY, isWide);

    document.documentElement.style.setProperty(
      '--size-sidebar-width',
      isWide ? `${WIDE_SIDEBAR_WIDTH_REM}rem` : `${NARROW_SIDEBAR_WIDTH_REM}rem`,
    );
  }, [isWide]);

  return { isWide, canToggle, expand, collapse, enable, disable };
}
