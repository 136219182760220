import { fromJS, Map } from 'immutable';
import { Action } from 'redux';

import { combineReducers } from 'store/utils';

import { combinedPatientsReducer } from './patients';

export type EntitiesState = Map<any, any>;

interface EntitiesAction extends Action<string> {
  payload?: any;
}

export const initialState: EntitiesState = fromJS({
  patients: {},
});

export function entitiesReducer(state: EntitiesState = initialState, action: EntitiesAction): EntitiesState {
  const entitiesReducer = combineReducers(combinedPatientsReducer);

  return entitiesReducer(state, action);
}
