/* eslint-disable ndte/intl-imports */

import { useQueryClient } from '@tanstack/react-query';

import { Capabilities, CAPABILITIES_QUERY_KEY, useCapabilities } from 'hooks/use-config';

export function useAppLocale() {
  const { capabilities } = useCapabilities();

  const locale = capabilities?.locale?.replace('_', '-');
  const country = capabilities?.configurations.country;

  if (locale && locale.indexOf('-') !== -1) return locale;
  if (locale && country) return `${locale}-${country}`;
  if (locale) return locale;

  return 'en-GB';
}

export function useUpdateCapabilitiesLocale() {
  const queryClient = useQueryClient();

  return (locale: string) => {
    queryClient.setQueryData<Capabilities>(CAPABILITIES_QUERY_KEY, (capabilities) => {
      if (capabilities) return { ...capabilities, locale };
    });
  };
}
