import { Action } from 'redux';

import { LocalStorage } from 'utils/storage';

import { WIDE_SIDEBAR_STORAGE_KEY } from 'hooks/use-sidebar-settings';

// Actions
//======================================================================================================================

type ExpandSidebarAction = Action<typeof EXPAND_SIDEBAR>;
export const EXPAND_SIDEBAR = 'EXPAND_SIDEBAR';

type CollapseSidebarAction = Action<typeof COLLAPSE_SIDEBAR>;
export const COLLAPSE_SIDEBAR = 'COLLAPSE_SIDEBAR';

type EnableToggleSidebarAction = Action<typeof ENABLE_TOGGLE_SIDEBAR>;
export const ENABLE_TOGGLE_SIDEBAR = 'ENABLE_TOGGLE_SIDEBAR';

type DisableToggleSidebarAction = Action<typeof DISABLE_TOGGLE_SIDEBAR>;
export const DISABLE_TOGGLE_SIDEBAR = 'DISABLE_TOGGLE_SIDEBAR';

// Action Creators
//======================================================================================================================

export function expandSidebar() {
  return { type: EXPAND_SIDEBAR };
}

export function collapseSidebar() {
  return { type: COLLAPSE_SIDEBAR };
}

export function enableToggleSidebar() {
  return { type: ENABLE_TOGGLE_SIDEBAR };
}

export function disableToggleSidebar() {
  return { type: DISABLE_TOGGLE_SIDEBAR };
}

// Reducer
//======================================================================================================================

type AppAction = ExpandSidebarAction | CollapseSidebarAction | EnableToggleSidebarAction | DisableToggleSidebarAction;

export interface AppState {
  sidebar: {
    isWide: boolean;
    canToggle: boolean;
  };
}

export const initialState: AppState = {
  sidebar: {
    isWide: LocalStorage.get(WIDE_SIDEBAR_STORAGE_KEY) || false,
    canToggle: true,
  },
};

export function appReducer(state: AppState = initialState, action: AppAction): AppState {
  switch (action.type) {
    case EXPAND_SIDEBAR: {
      return { ...state, sidebar: { ...state.sidebar, isWide: true } };
    }

    case COLLAPSE_SIDEBAR: {
      return { ...state, sidebar: { ...state.sidebar, isWide: false } };
    }

    case ENABLE_TOGGLE_SIDEBAR: {
      return { ...state, sidebar: { ...state.sidebar, canToggle: true } };
    }

    case DISABLE_TOGGLE_SIDEBAR: {
      return { ...state, sidebar: { ...state.sidebar, canToggle: false } };
    }

    default: {
      return state;
    }
  }
}
